import React from 'react';
import { Router } from 'buttermilk';

const countryList = ['hk', 'tw', 'th', 'vn', 'ph'];
const langMap = {
  hk: ['en', 'tc'],
  tw: ['tc'],
  th: ['en', 'th'],
  vn: ['en', 'vi'],
  ph: ['en'],
};

const { REACT_APP_REDIRECT_URL } = process.env;

const Redirect = props => {
  const { pathname } = props.location;
  let [, country, lang] = pathname.split('/');

  let url = REACT_APP_REDIRECT_URL || 'https://web.lalamove.com/';

  if (country) country = country.toLowerCase();
  if (lang) lang = lang.toLowerCase();
  if (country && countryList.includes(country)) {
    url = url + '?location=' + country.toUpperCase();
    if (lang
      && langMap[country]
      && langMap[country].includes(lang)
    ) {
      if (lang === 'tc') lang = 'zh-' + country.toUpperCase();
      url = url + '&locale=' + lang;
    }
  }

  window.location.href = url;
  return null;
};

const App = () =>(
  <Router
    routes={[
      {
        path: '*',
        render: Redirect,
      },
    ]}
  />
);

export default App;
